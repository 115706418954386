import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import img404 from "../images/404.svg"

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="404: Not found" />
    <Container>
      <img src={img404} alt="erro" />
      <h1>NÃO ENCONTRADO</h1>
      <p>A página que você tentou acessar não existe...</p>
      <Link to="/">voltar para a página inicial</Link>
    </Container>
  </Layout>
)

const Container = styled.div`
  height: 600px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 200px;
    padding-bottom: 13px;
  }

  a {
    padding-top: 20px;
    color: #707070;
    &:hover {
      color: #f44336;
    }
  }

  @media (max-width: 500px) {
    text-align: center;
    img {
      padding-bottom: 18px;
    }
  }
`

export default NotFoundPage
